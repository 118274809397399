// Features
$enable-rounded:            false;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        false;
$enable-hover-media-query:  false;
$enable-grid-classes:       false;
$enable-print-styles:       false;

// Colors
$blue-dark: #153041;
$blue-gray: #606A7C;
$gray: #7B7B7B;
$gray-dark: #272322;
$green: #A8D341;
$blue: #2276AA;
$yellow: #D3B641;
$red: #D34141;
$gray-light: #e5e4e4;
$gray-lighter: #eee;


$theme-colors: (
	primary: $blue-dark,
	secondary: $gray-dark,
	success: $green,
	info: $blue,
	warning: $yellow,
	danger: $red,
	light: $gray-light,
	dark: $gray-dark
);
$body-bg: #fff;
$headings-font-weight: 400;
$headings-line-height: 1;
$font-family-serif: 'BBCNassimBold', serif;
$headings-font-family: $font-family-serif;
$font-family-sans-serif: 'AsapRegular', sans-serif;
$link-hover-decoration: none;

// Grid
$grid-gutter-width: 45px;

$grid-breakpoints: (
	xxs: 0,
	xs: 540px,
	sm: 768px,
	md: 1024px,
	lg: 1280px,
	xl: 1440px,
	xxl: 1920px
);

$container-max-widths: (
	xs: 540px,
	sm: 692px,
	md: 948px,
	lg: 1128px,
	xl: 1288px,
	xxl: 1768px
);

// Varia
$transf: cubic-bezier(.14,1,.66,1);
$shadow: 0 0 1em 0 rgba(0,0,0,0.2);
$shadow-light: 0 0 1em 0 rgba(0,0,0,0.12);
$shadow-corners: 0.8em 0.8em 1em -0.95em rgba(0,0,0,0.2), -0.8em -0.8em 1em -0.95em rgba(0,0,0,0.2);
$shadow-bottom: 0 1em 1em 0 rgba(0,0,0,0.2);
$shadow-bottom-light: 0 1em 1em 0 rgba(0,0,0,0.12);
$shadow-corners-bottom: 3em 3em 1em -3em rgba(0,0,0,0.2);
$radius: 0.166666666666666em;
$border-radius: $radius;
$border-width: 0.12em;
