html{
	font-size: 18px;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	will-change: scroll-position;

	// @include media-breakpoint-up(xl) {
	// 	font-size: 20px;
	// }

	// @include media-breakpoint-up(xxl) {
	// 	font-size: 22px;
	// }
}

// #main{
	// @include clearfix;
// }

ul{
	padding-left: 1.5em;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
	margin-bottom: 1rem;
}

.icon{
	fill: currentColor;
	// fill: $blue-dark;
	vertical-align: middle;
}
.logo{
	.icon{
		width: 100%;
		height: auto;
	}
}
.shadow{
	box-shadow: $shadow;
}
.shadow-bottom{
	box-shadow: $shadow-corners-bottom;
}

.container{
	@include make-container;
}

.container-w{
	@include make-container;
	@include make-container-max-widths;
}

.flipped-v{
	transform:scaleY(-1);
}

.flipped-h{
	transform:scaleX(-1);
}

.container-w-f{
	@include make-container;
	@include make-container-max-widths;
	padding-left: 0;
	padding-right: 0;
}

.title-lined{
	margin-bottom: 0.5em;

	span{
		display: inline-block;
		padding-bottom: 0.5em;
		border-bottom: 0.15rem solid currentColor;
	}
}

.white{
	background-color: #fff;
	color: $blue-dark;

	// .icon:not(.btn>.icon){
		// fill: $blue-dark;
	// }
}
.black{
	background-color: #000;
	color: #fff;

	// .icon:not(.btn>.icon){
	// 	fill: #fff;
	// }
}
.gray{
	background-color: $gray-light;
	color: $blue-dark;

	// .icon:not(.btn>.icon){
	// 	fill: $blue-dark;
	// }
}
.blue{
	background-color: $blue-dark;
	color: #fff;

	// .icon:not(.btn>.icon){
	// 	fill: #fff;
	// }
}

img.alignleft{
	float: left;
	margin-right: 1em;
	margin-bottom: 1em;
	clear: both;
}

/**
 * Tooltips CDB theme
 */
dfn.cdb-glossary-word{
	font-style: normal;
	
	&, &:link, &:visited{
		cursor: pointer;
		color: $blue;
	}
	
	&:hover, &:focus{
		color: $blue;
	}
	
	&:active{
		color: $blue;
	}
}

.img-wrap{
	border-radius: $radius;
	box-shadow: $shadow;
	overflow: hidden;
	display: block;

	@include media-breakpoint-up(lg) {
		display: inline-block;
	}
}

.img-wrap-light{
	border-radius: $radius;
	box-shadow: $shadow-light;
	overflow: hidden;
	display: block;

	@include media-breakpoint-up(lg) {
		display: inline-block;
	}
}

.wrap{
	background: #fff;

	.home &{
		// margin-top: -10vh;
		// padding-top: 100vh;
	}
}

.rad{
	border-radius: $radius;

	&-top{
		border-top-left-radius: $radius;
		border-top-right-radius: $radius;
	}

	&-bot{
		@include media-breakpoint-up(xs) {
			border-bottom-left-radius: $radius;
			border-bottom-right-radius: $radius;
		}
	}
}

.screen-reader-text{
	@include text-hide;
}

.row{
	@include make-row;
}

.col-left{
	@include make-col-ready;
	@include make-col(12);
	position: relative;

	@include media-breakpoint-up(md) {
		@include make-col(8);
	}

	// @include media-breakpoint-up(xxl) {
	// 	@include make-col(7);
	// }
}

.col-right{
	@include make-col-ready;
	@include make-col(12);
	@include media-breakpoint-up(md) {
		@include make-col(4);
	}
	// @include media-breakpoint-up(xxl) {
	// 	@include make-col(5);
	// }
}

/**
 * Pagination
 */
.pagination{
	@include make-col-ready;
	@include make-col(12);

	&-list{
		list-style: none;
		margin: 0;
		padding: 0 2rem;
		display: flex;
		justify-content: center;
		font-size: 0.7em;

		@include media-breakpoint-up(sm) {
			padding: 0;
		}
		@include media-breakpoint-up(md) {
			font-size: 1em;
		}

		&-item{
			margin-left: 0.4em;

			@include media-breakpoint-up(md) {
				margin-left: 1em;
			}

			@include media-breakpoint-up(md) {
				a.prev, a.next, a.prev:link, a.next:link, a.prev:visited, a.next:visited{
					border-bottom: $border-width solid transparent;
					transition: border-color 250ms $transf;
				}
				
				a.prev:hover, a.next:hover, a.prev:focus, a.next:focus{
					border-bottom: $border-width solid $blue-dark;
				}
				
				a.prev:active, a.next:active{
					border-bottom: $border-width solid transparent;
				}
			}
			

			.page-numbers:not(.next):not(.prev){
				display: inline-block;
				text-align: center;
				width: 2em;
				height: 2em;
				border-radius: 200%;
				line-height: 2em;
			}

			.page-label{
				display: none;

				@include media-breakpoint-up(md) {
					display: inline;
				}
			}

			a.page-numbers:not(.next):not(.prev), a.page-numbers:not(.next):not(.prev):link, a.page-numbers:not(.next):not(.prev):visited{
				transition: background-color 250ms $transf;
			}
			
			a.page-numbers:not(.next):not(.prev):hover, a.page-numbers:not(.next):not(.prev):focus{
				background: $blue-dark;
				color: #fff;
			}
			
			a.page-numbers:not(.next):not(.prev):active{
				
			}

			.current{
				background: $blue-dark;
				color: #fff;
			}
		}

		&-item:first-child{
			margin-left: 15em; // 11 + 2 + 2
		}
		&-item.pagination-list-item-prev:first-child{
			margin-left: 0;
			width: 11em;
		}

		&-item:last-child{
			margin-right: 15em; // 11 + 2 + 2
		}
		&-item.pagination-list-item-next:last-child{
			margin-right: 0;
			width: 11em;
			text-align: right;
		}
	}
}

.nav{
	position: fixed;
	top: 0;
	z-index: 10000;
	width: 100%;
	transform: translate3d(0,0,0);
	backface-visibility: hidden;

	&-in{
		overflow: hidden;
		height: 3.8rem;
		transition: height 250ms $transf;

		@include media-breakpoint-up(md) {
			height: 5rem;
			.nav-scrolled &{
				height: 3.8rem;
			}
		}
	}

	&-row{
		align-items: stretch;
		height: 100%;
	}

	&-logo{
		&-in{
			display: flex;
			flex-direction: row;
			position: relative;
			width: 100%;
		}
		
		color: #fff;
  		padding: 0 ($grid-gutter-width / 2);
		// @include make-col-ready;
		height: 100%;
		display: flex;
		// transition: background-color 250ms $transf, width 250ms $transf;
		transition: background-color 250ms $transf;
		// position: relative;
		// flex: 0 0 326px;
		width: auto;
		flex: 1;

		@include media-breakpoint-up(md) {
			width: 226px;
			flex: 0 0 226px;

			.nav-scrolled &{
				width: 116px;
				flex: 0 0 116px;
			}
		}

		// @include media-breakpoint-up(lg) {
		// 	width: 286px;
		// }
		
		@include media-breakpoint-up(lg) {
			width: 326px;
		}

		// @include make-col(3);
		// padding-top: 1.15rem;
		// padding-bottom: 1.15rem;
		// padding: 1.15rem 2rem;

		.icon{
			align-self: center;
		}

		.icon.icn-signature{
			width: 100%;
			max-width: 176px;
			// flex-basis: 276px;
			flex-shrink: 0;
			// max-width: none;
			opacity: 1;
			transform:translate3d(0,0,0);
			transition: opacity 250ms $transf, transform 250ms $transf;

			@include media-breakpoint-up(md) {
				width: 176px;

				.nav-scrolled &{
					width: 116px;
					// flex-basis: 116px;
					transform:translate3d(0,-50%,0);
					opacity: 0;
				}
			}
			@include media-breakpoint-up(lg) {
				width: 276px;
			}
		}

		.icon.icn-symbole{
			position: absolute;
			width: 50px;
			left: 50%;
			transform:translate3d(-50%, 50%,0);

			opacity: 0;
			transition: opacity 250ms $transf, transform 250ms $transf;

			@include media-breakpoint-up(md) {
				.nav-scrolled &{
					transform:translate3d(-50%, 0,0);
					opacity: 1;
				}
			}
		}
			
		&:hover, &:focus{
			color: #fff;
			background: #000;
		}
	}

	&-list{
		// @include make-col-ready;
		@include make-container-max-widths();
		position: fixed;
		height: calc(100vh - 3.7rem);
		width: 100%;
		left: 50%;
		top: 3.7rem;
		z-index: -1;
		transition: transform 250ms $transf;
		transform: translate3d(-50%, -110%, 0);
		
		display:flex;
		align-items: center;
		justify-content: space-around;
		flex: 1;
		flex-direction: column;

		list-style: none;
		margin: 0;
		padding: 0;
		background: $blue-dark;

		&.open{
			transform: translate3d(-50%,0,0);
		}

		@include media-breakpoint-up(md) {
			position: static;
			flex-direction: row;
			padding-top: 0;
			height: 100%;
			background:none;
			transform: none;
			z-index: auto;
		}

		li{
			flex-grow: 1;
			display: flex;
			// height: 100%;
			
			width: 100%;
			border-bottom: 1px solid rgba(0,0,0,0.12);

			@include media-breakpoint-up(md) {
				width: auto;
				height: 100%;
			}

			a, a:link, a:visited{
				width: 100%;
				// height: 3rem;
				// align-self: center;
				flex: 1;
				justify-content: center;
  				// flex-direction: column;
				display: flex;
				align-items: center;
				text-align: center;

				// padding: 2.05rem ($grid-gutter-width / 2);
				font-size: 0.77777777em;
				color: #fff;
				text-transform: uppercase;

				transition: background-color 250ms $transf;
			}
			
			a:hover, a:focus, &.active a{
				background: #000;
			}
			
			a:active{
				color: #fff;
			}
			
		}
	}

	&-search{
		align-items: center;
		justify-content: center;
		flex-direction: column;
		display: flex;
		height: 100%;
		text-align: center;
		// padding: 1.9rem ($grid-gutter-width / 2);
		transition: background-color 250ms $transf;
		
		width: 64px;
		flex: 0 0 64px;
		margin-left: auto;

		@include media-breakpoint-up(sm) {
			width: 94px;
			flex: 0 0 94px;
		}

		&, &:link, &:visited{
			color: #fff;
			background: $blue-dark;
		}

		&.has-open, &:hover, &:focus{
			color: #fff;
			background: #000;
		}

		&:active{
			background: $blue-dark;
		}

		.icn-x{
			display: none;
		}
		&.has-open{
			.icn-x{
				display: inline-block;
			}
			.icn-magnify{
				display: none;
			}
		}
	}

	&-mobile{
		color: #fff;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		display: flex;
		height: 100%;
		text-align: center;
		transition: background-color 250ms $transf;

		width: 124px;
		flex: 0 0 124px;
		// margin-left: auto;

		@include media-breakpoint-up(sm) {
			width: 154px;
			flex: 0 0 154px;
		}

		@include media-breakpoint-up(md) {
			display: none;
		}

		&-label{
			margin-left: 1ex;
			text-transform: uppercase;
		}

		&, &:link, &:visited{
			background: $blue-dark;
		}

		&:hover, &:focus, &:active{
			background: #000;
			color: #fff;
		}
		@media (hover:none), (hover:on-demand) {
			&:hover{
				background: $blue-dark;
			}
		}
		&.has-open{
			background:#000;
		}

		.icn-x{
			display: none;
		}
		&.has-open{
			.icn-x{
				display: inline-block;
			}
			.icn-hamburger{
				display: none;
			}
		}
	}

	body.admin-bar &{
		top: 46px;
	}

	@media screen and (min-width: 783px) {
		body.admin-bar &{
			top: 32px;
		}
	}
}

.hd{
	position: relative;
	z-index: 1;

	.home &{
		height: 100vh;
	}

	&-content{
		position: absolute;
		top: 70%;
		z-index: 4;
		left: 50%;
		transform: translate(-50%,-70%);
		
		width: 94%;
		@include media-breakpoint-up(md) {
			width: 82%;
		}

		&-tagline{
			text-align: center;
			font-family: $font-family-serif;
			color: #fff;
			margin: 0;
			
			font-size: 1.8rem;

			@include media-breakpoint-up(sm) {
				font-size: 3.2rem;
			}
			@include media-breakpoint-up(lg) {
				font-size: 4rem;
			}
		}

		&-ctas{
			margin-top: 10vh;
			text-align: center;

			font-size: 0.6rem;

			@include media-breakpoint-up(sm) {
				font-size: 1rem;
			}

			a{
				margin-bottom: 1em;
			}

			a:not(:last-child){
				margin-right: 2ex;
			}
		}
	}

	&-slider-wrapper{
		position: fixed;
		top: 0;
		z-index: -2;
		overflow: hidden;
		width: 100%;
		// max-height: 100vh;
		height: 100vh;
		left: 50%;
		transform:translate(-50%,0);
	}

	&-slider{
		// max-height: 100vh;
		height: 100vh;
		&:before{
			content:' ';
			background: $blue-gray;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 3;
			width: 100%;
			height: 100%;
			display: block;
			opacity: 0.2;
		}

		.swiper-slide{
			height: 100%;
			object-fit:cover;
			font-family: 'object-fit: cover;';
			align-self: flex-start;
		}
	}

	img{

		// max-width: 100%;
		// height: auto;
	}

	&-banner{
		position: fixed;
		z-index: -2;
		top: 68px;
		left: 50%;
		transform: translate(-50%,0);
		width: 100%;

		img{
			// min-width: 1440px;
			width: 600px;
			height: auto;

			@include media-breakpoint-up(xs) {
				min-width: 900px;
				width: 100%;
				height: auto;
			}

			@include media-breakpoint-up(md) {
				min-width: 1440px;
				width: 100%;
				height: auto;
			}
		}

		@include media-breakpoint-up(xs) {
			top: 0;
		}

		&:after{
			content: ' ';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			background: fade-out($blue-gray, 0.55);
		}
	}
}

.swiper-pagination{
	position: static;
	margin-top: 10vh;
	margin-left: auto;
	margin-right: auto;

	.swiper-pagination-bullet{
		cursor: pointer;
		opacity: 1;
		width: 2rem;
		border-radius:1rem;
		background: $blue-dark;
		// border: 2px solid #fff;
		position: relative;
		transition: background-color 250ms $transf;
		margin-right: 2ex;

		&:focus{
			outline: none;
		}

		.outline{
			overflow: visible;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			stroke-dashoffset: 84;
			stroke-dasharray: 84;

			.js.loaded &{
				transition: stroke-dashoffset 750ms $transf;
			}
		}

		.js.loaded &-active, &-active{
			background: #000;

			.outline{
				stroke-dashoffset: 0;
			}
		}
	}
}

/**
 * Searchbar
 */
.searchbar{
	position: fixed;
	top: 68px;
	z-index:20;
	width: 100%;
	transform: translate3d(0,-110%,0);
	// opacity: 0;
	// transition: transform 250ms $transf, opacity 0ms $transf 250ms;
	transition: transform 250ms $transf;

	@include media-breakpoint-up(md) {
		top: 80px;
	}
	
	&.open{
		// opacity: 1;
		transform: translate3d(0,0,0);
		// transition: transform 250ms $transf, opacity 0ms $transf;
		transition: transform 250ms $transf;
	}


	body.home &{
		position: relative;
		top: auto;
		background: $blue-dark;
		border-top: 1px solid transparent; // Allow negative offset to work
		transform: none;
	}

	&-in{
		@extend .rad;
		box-shadow: $shadow;
		background: #fff;
		// padding-top: 1rem;
		// padding-bottom: 1rem;
		padding: 1.25rem 2rem 1.75rem;

		// & > .row{
		// 	// margin-left: -2rem;
		// 	// margin-right: -2rem;
		// }
	}
	body.home &-in{
		margin-top: -3rem;
	}

	&-row{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-left: -1rem;
		margin-right: -1rem;
		align-items: center;
	}

	&-title{
		font-size: 1.25em;
		padding-left: 1rem;
		padding-right: 1rem;
		align-self: baseline;

		width: 100%;
		text-align: center;
		@include media-breakpoint-up(md) {
			text-align: left;
			width: 50%;
		}
	}

	&-more{
		padding-left: 1rem;
		padding-right: 1rem;
		text-align: right;
		align-self: baseline;
		font-size: 0.85em;

		width: 100%;
		order: 4;
		@include media-breakpoint-up(md) {
			order: 0;
			width: 50%;
		}
	}

	&-col{
		padding-left: 1rem;
		padding-right: 1rem;

		width: 100%;
		margin-bottom: 1rem;
		@include media-breakpoint-up(md) {
			width: 24%;
			margin-bottom: 0;
		}
		@include media-breakpoint-up(lg) {
			width: 25%;
		}
		// @include media-breakpoint-up(xl) {
		// 	width: 27%;
		// }
	}

	&-button{
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 28%;
		}
		@include media-breakpoint-up(lg) {
			width: 25%;
		}
		// @include media-breakpoint-up(xl) {
		// 	width: 19%;
		// }
		// flex-grow:1;

		.btn{
			width: 100%;
			.icn-arrow-h{
				display: none;
			}
			.label-view{
				display: none;
			}

			&.view{
				.icn-magnify{
					display: none;
				}
				.icn-arrow-h{
					display: inline-block;
				}

				.label-search{
					display: none;
				}
				.label-view{
					display: inline-block;
				}
			}
		}
		// width: 240px;
	}

	&-select{
		select{
			width: 100%; // Counter jquery's rounding from outerWidth in select2.js
		}
	}

	.select2{
		// width:calc(100% - 1px);
	}
}

/**
 * Select2 cdb theme
 */
.select2-dropdown--below{
	box-shadow: $shadow-bottom;
}
.select2{
	&.select2-container--cdb{
		width: 100%;
	}

	&-container{
		&--cdb, &--cdb-dark{
			transition: background-color 250ms $transf;

			&:not(.select2-container--open):not(.select2-container--disabled):hover{
				background-color: $gray-light;
			}

			.blue &:not(.select2-container--open):not(.select2-container--disabled):hover{
				background-color: #000;
			}

			&.select2-container--open{
				box-shadow: $shadow;
			}

			&.select2-container--disabled{
				opacity: 0.5;
			}

			// &.select2-container--focus{
			// 	outline:none;
			// }

			.select2-selection--single{
				border: $border-width solid $blue-dark;
				border-radius: $radius;
				height: auto;
				outline: none;

				.select2-selection__rendered{
					padding: 0.6em 44px 0.6em 1em;
				}
			}

			.select2-selection__arrow{
				width: 22px;
				height: 12px;
				position: absolute;
				top: 50%;
				transform: translate(0,-50%);
				right: 11px;
				background: url(../images/icn-caret-v.svg) center no-repeat;
			}

			.select2-dropdown{
				border: $border-width solid $blue-dark;

				// border-top: 0;

				&.select2-dropdown--above{
					border-bottom: 0;
					margin-top: 0.16em;
				}

				&.select2-dropdown--below{
					border-top: 0;
					margin-top: -0.16em;
				}
			}

			.select2-search--dropdown{
				padding: 0.7em 1em;
				// border: $border-width solid $blue-dark;
				border-radius: $radius;

				.select2-search__field{
					background-color: $gray-light;
					border:none;		
					// border: $border-width solid $blue-dark;
					border-radius: $radius;
				}
			}


			.select2-results{
				max-height: 10.8rem;
				overflow: scroll;

				& > .select2-results__options{
					// padding: 0.6em 1em;
				}
			}
			.select2-results__option{
				padding: 0.6em 1em;
				transition: background-color 250ms $transf, color 250ms $transf;
			}
			.select2-results__option[aria-selected="true"]{
				background-color: $gray-light;
			}
			.select2-results__option--highlighted[aria-selected] {
				background-color: $blue-dark;
				color: #fff;
			}
		}

		&--cdb-dark{
			&:not(.select2-container--open):not(.select2-container--disabled):hover{
				background-color: #000 !important;
			}

			.select2-dropdown{
				border-color: #fff;
				background: $blue-dark;
				color: #fff;
			}

			.select2-selection--single{
				border: $border-width solid #fff;
			}

			.select2-selection__arrow{
				background: url(../images/icn-caret-v-white.svg) center no-repeat;
			}

			.select2-results__option[aria-selected="true"]{
				background-color: #000;
			}
			.select2-results__option--highlighted[aria-selected] {
				background-color: #fff;
				color: $blue-dark;
			}
		}
	}
}


.nav-inline{
	list-style: none;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin: 0;
	padding: 0;
	line-height: 2;
	flex-wrap:wrap;

	li{
		padding: 0 1ex;
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		li:first-child{
			padding-left: 2ex;
		}

		li:last-child{
			padding-right: 2ex;
		}
	}
	// align-items: center;
	// align-self: center;
}

.ft{
	position: relative;
	z-index: 10;
	background: $blue-dark;
	padding: 3rem 0;
	color: #fff;
	
	&-row{
		align-items: center;
	}

	&-nav{
		@include make-col-ready;
		@include make-col(12);
		@include media-breakpoint-up(md) {
			@include make-col(9);
		}

		&-list{
			

			a, a:link, a:visited{
				color: #fff;
				padding-bottom: $border-width;
				border-bottom: $border-width solid transparent;
				transition: border-bottom-color 250ms $transf;

				.icon{
					margin-bottom: 0.3em;
				}
			}
			
			a:hover, a:focus{
				border-bottom-color: #fff;
			}
			
			a:active{
				color: #fff;
			}
		}
	}

	&-logo{
		@include make-col-ready;
		@include make-col(6);
		@include make-col-offset(3);
		margin-bottom: 2rem;
		@include media-breakpoint-up(md) {
			@include make-col(3);
			@include make-col-offset(0);
			margin-bottom: 0;
		}
		position: relative;

		.icon{
			fill: #fff;
		}

		.icon.icn-signature{
			position: relative;
			z-index: 2;
			width: 100%;
			height: auto;
		}

		.icon.icn-symbole-mini{
			position: absolute;
			z-index: 1;
			top: 0;
			opacity: 0.08;
			width: 100%;
			height: auto;
			left: 50%;
			transform:translate3d(-51.5%, -30%, 0);
		}
	}

	// &-social{
	// 	@include make-col-ready;
	// 	@include make-col(1);
	// 	text-align: center;

	// 	a, a:link, a:visited{
			
	// 	}
		
	// 	a:hover, a:focus{
	// 		.icon{
	// 			fill: #fff;
	// 		}
	// 	}
		
	// 	a:active{
			
	// 	}
		
	// }
}

/**
 * Blocks
 */
.tax-card{
	.img-wrap, .img-wrap-light{
		width: 100%;
	}

	&-image{
		width: 100%;
		height: auto;
	}

	.img-empty{
		display: block;
		height: 0;
		padding-bottom: 97.5%;
		background: $gray-light;
		border-radius: $radius;
		box-shadow: $shadow-light;
		overflow: hidden;
	}

	&-bottom{
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}

	&-button{
		font-family: $font-family-serif;
		font-size: 0.8em;
		line-height: 1;
		position: relative;
		z-index: 1;
		width: 100%;

		transform:translate3d(0,-50%,0);

		@include media-breakpoint-up(sm) {
			font-size: 1em;
		}

		@include media-breakpoint-up(md) {
			font-size: 1.3em;
		}
	}
}

.blocks-wrap{
	position: relative;
	z-index: 2;
	// ??
	overflow: hidden;
}

.block{
	padding: 2rem;

	@include media-breakpoint-up(lg) {
		padding: 4rem 0;
	}
	
	&:first-child{
		padding-top: 0;
	}
	.home &:first-child{
		padding-top: 4rem;

		@include media-breakpoint-up(lg) {
			padding-top: 8rem;
		}
	}

	&:last-child{
		padding-bottom: 4rem;

		@include media-breakpoint-up(lg) {
			padding-bottom: 8rem;
		}
	}

	&-duo-image-text &-row{
		align-items: center;
	}

	&-title{
		font-size: 2em;
		margin-bottom: 1rem;

		@include media-breakpoint-up(md) {
			font-size: 3.5em;
			margin-bottom: 1rem;
		}
	}
	&.block-duo-text &-title,
	&.block-single-text &-title{
		font-size: 1.5em;

		@include media-breakpoint-up(md) {
			font-size: 2em;
		}
	}
	&-title-in{
		display: inline-block;
		padding-bottom: 1rem;
		border-bottom: 0.15rem solid currentColor;
	}
	&.block-offset-title &-title{
		// @include make-col-offset(-5.16666);
		margin-top: -3em;
		padding-bottom: 1rem;
		border-bottom: 0.15rem solid currentColor;
		margin-bottom: 2rem;
		font-size: 2rem;

		@include media-breakpoint-up(md) {
			font-size: 3.5em;
		}

		@include media-breakpoint-up(lg) {
			margin-top: 2rem;
			margin-left: percentage(-5.16666/12);
		}
	}
	&.block-offset-title &-title-in{
		border:none;
	}
	&.block-image-right.block-offset-title &-title{
		margin-left: 0;
		
		@include media-breakpoint-up(lg) {
			margin-right: percentage(-5.16666/12);
		}
	}

	&-col{
		@include make-col-ready;
		@include make-col(12);

		img{
			max-width: 100%;
			height: auto;
		}
		
		&:not(:last-child){
			margin-bottom: 4rem;
		}

		@include media-breakpoint-up(lg) {
			@include make-col(6);
			margin-bottom: 0;
		}

		&-no-title{
			padding-top: 4rem;
		}
	}

	&-col-single{
		@include make-col-ready;
		@include make-col(12);
		
		&:not(:last-child){
			margin-bottom: 4rem;
		}

		&-no-title{
			padding-top: 4rem;
		}
	}

	&-col-image{
		@include make-col-ready;
		@include make-col(12);

		@include media-breakpoint-up(lg) {
			@include make-col(7);
			margin-bottom: 0;
		}

		// @include media-breakpoint-up(lg) {
		// 	@include make-col(7);
		// }
	
		img{
			width: 100%;
			height: auto;
		}

		.img-wrap{
			width: 100%;
		}
	}
	&.block-image-right &-col-image{
		order: 2;	
	}
	&.block-offset-title &-col-image .img-wrap{
		width: auto;
		margin-left: -15vw;
		margin-right: -15vw;

		@include media-breakpoint-up(lg) {
			margin-left: 0;
			margin-right: 0;
			width: 100%;
		}
	}

	&-col-image-content{
		position: relative;
		z-index: 2;
		@include make-col-ready;
		@include make-col(12);
		margin-bottom: 2rem;

		@include media-breakpoint-up(lg) {
			@include make-col(5);
			margin-bottom: 0;
		}

		// @include media-breakpoint-up(lg) {
		// 	@include make-col(5);
		// }
	}

	&-tax-slider{

		&-title{
			@include make-col-ready;
			@include make-col(12);

			text-align: center;
			margin-bottom: 0.5em;

			@include media-breakpoint-up(sm) {
				text-align: right;
				margin-bottom: 0;
			}
		}

		&-sub-title{
			@include make-col-ready;
			@include make-col(12);

			text-align: right;
		}

		&-col{
			@include make-col-ready;
			@include make-col(12);
			margin-top: 2rem;
		}

		.swiper-wrapper{
			// @includ make-row;
		}

		.swiper-slide{
			// @include make-col-ready;
			// @include make-col(4);
			width: 33.33%;
			padding: 0;
		}

		.swiper-button-prev, .swiper-button-next{
			background: $blue-dark;
			border-radius: 200%;
			box-shadow: $shadow;
			text-align: center;
			transition: background-color 250ms $transf;

			width: 1.2rem;
			height: 1.2rem;
			line-height: 1rem;

			@include media-breakpoint-up(sm) {
				width: 2rem;
				height: 2rem;
				line-height: 1.8rem;
			}

			.icon{
				fill: #fff;
			}

			&:hover{
				background-color: #000;
			}
		}

		.swiper-button-prev{
			left: 1.4rem;
			top: -2rem;

			@include media-breakpoint-up(sm) {
				left: 1.2rem;
				top: -2.5rem;
			}
			
			@include media-breakpoint-up(lg) {
				top: 50%;
				left: -2rem;
			}
		}

		.swiper-button-next{
			left: 3.2rem;
			top: -2rem;

			@include media-breakpoint-up(sm) {
				left: 4rem;
				top: -2.5rem;
			}

			@include media-breakpoint-up(lg) {
				top: 50%;
				left: auto;
				right: -2rem;
			}
		}

		.swiper-button-prev .icon{
			margin-right: 0.2rem;
		}

		.swiper-button-next .icon{
			margin-left: 0.2rem;
		}
	}

	&-tax-grid{
		&-element{
			@include make-col-ready;
			@include make-col(12);
			margin-bottom: ($grid-gutter-width / 2);

			@include media-breakpoint-up(sm) {
				@include make-col(6);
			}
			@include media-breakpoint-up(lg) {
				@include make-col(4);
			}
		}
	}

	&-links{
		&-link{
			@include make-col-ready;
			@include make-col(12);
			@include media-breakpoint-up(sm) {
				@include make-col(6);
			}
			@include media-breakpoint-up(lg) {
				@include make-col(4);
			}
			margin-bottom: $grid-gutter-width;

			&-in{
				padding: 1rem;
				height: 100%;
				display: flex;
				flex-direction: column;
			}

			&-title{
				// @include make-col-ready;
				// @include make-col(3);
				// font-size: 1rem;
				// font-family: $font-family-sans-serif;
				font-weight: bold;
				margin: 0 0 1rem 0;
			}

			&-url{
				margin-top: auto;
				align-self: flex-end;
				a{
					@extend .btn;
				}
				// @include make-col-ready;
				// @include make-col(4);
			}

			&-desc{
				// margin-bottom: 1rem;
				// @include make-col-ready;
				// @include make-col(5);
			}
		}
	}

	&-col-text{
		a:not(.cdb-glossary-word):not(.btn){
			&, &:link, &:visited{
				border-bottom: $border-width solid currentColor;
			}
			
			&:hover, &:focus{
				
			}
			
			&:active{
				
			}
			
		}

		// ul,ol,dl{
		// 	// line-height: 2;
		// }
		
	}
}

.htop{
	// font-size: 1em;
	// @include media-breakpoint-up(sm) {
	// 	// font-size: 0.92em;
	// }
	// @include media-breakpoint-up(md) {
	// 	font-size: 0.7em;
	// }
	// @include media-breakpoint-up(lg) {
	// 	font-size: 0.84em;
	// }
	// @include media-breakpoint-up(xl) {
	// 	font-size: 1em;
	// }
	// @include media-breakpoint-up(xxl) {
	// 	font-size: 1.4em;
	// }


	margin-bottom: 4em;
	padding: 0 2rem;
	@include media-breakpoint-up(md) {
		padding: 0;
		margin-bottom: 8em;
	}

	body.tax-type-architectural-2015 &,
	body.single-propriete &{
		.col-left{
			@include make-col-ready;
			@include make-col(12);
			position: relative;

			@include media-breakpoint-up(xl) {
				@include make-col(8);
			}

			// @include media-breakpoint-up(xxl) {
			// 	@include make-col(7);
			// }
		}

		.col-right{
			@include make-col-ready;
			@include make-col(12);
			@include media-breakpoint-up(xl) {
				@include make-col(4);
			}
			// @include media-breakpoint-up(xxl) {
			// 	@include make-col(5);
			// }
		}
	}


	// .tax-type-architectural-2015 & .col-left{
	// 	@include make-col(12);
	// 	@include media-breakpoint-up(md) {
	// 		@include make-col(12);
	// 	}
	// 	@include media-breakpoint-up(lg) {
	// 		@include make-col(7);
	// 	}
	// }
	// .tax-type-architectural-2015 & .col-right{
	// 	@include make-col(12);

	// 	@include media-breakpoint-up(md) {
	// 		@include make-col(12);
	// 	}

	// 	@include media-breakpoint-up(lg) {
	// 		@include make-col(5);
	// 	}
	// }

	// &-reverse &-col-left{
	// 	order: 1;
	// }

	// &-reverse &-col-right{
	// 	order: 2;
	// }

	&-no-thumbnail &-title{
		border:none;
	}
	&-no-thumbnail &-down{
		right: ($grid-gutter-width / 2);
	}

	body.single-propriete &-vertical{
		.col-left{
			@include media-breakpoint-up(md) {
				@include make-col(4);
			}
		}
		.col-right{
			@include media-breakpoint-up(md) {
				@include make-col(8);
			}
		}
	}
	// &-no-thumbnail{
	// 	text-align: center;
	// }
	// &-no-thumbnail &-infos{
	// 	text-align: center;
	// }
	// &-no-thumbnail .row{
	// 	justify-content: center;
	// }
	// &-no-thumbnail &-down{
	// 	left: 50%;
	// 	transform: translate(-50%, 0);
	// 	// position: static;
	// 	// margin-bottom: -4em;
	// }

	&-picture-wrapper{
		margin-top: -3em;
		@include media-breakpoint-up(xl) {
			margin-bottom: -3em;
		}

		&-empty{
			min-height: 600px;
			height: 100%;
			@include media-breakpoint-up(xl) {
				height: calc(100% + 6em);
			}
		}
	}
	&-vertical &-picture-wrapper{
		@include media-breakpoint-up(lg) {
			margin-bottom: -3em;
		}
	}

	&-picture{
		width: 100%;
		height: auto;
	}

	.img-empty{
		height: 100%;
		// padding-bottom: 68.957346%;
		background: $gray-light;

		border-radius: $radius;
		box-shadow: $shadow;
		overflow: hidden;
	}

	&-back{
		@include media-breakpoint-up(xl) {
			margin-top: -3em;
		}

		&-link, &-link:link, &-link:visited{
			color: #fff;
		}
		
		&-link:hover, &-link:focus{
			
		}
		
		&-link:active{
			color: #fff;	
		}

		.icon{
			fill: #fff;
		}
	}
	&-vertical &-back{
		@include media-breakpoint-up(md) {
			margin-top: -3em;
		}
	}

	&-infos{
		text-align: left;
		margin: 0;
		// margin: 0 0 2em 0;
		padding: 0;

		&:not(:last-child){
			margin-bottom: 2em;
		}
		dt{
			text-transform: uppercase;
		}

		dd{
			margin: 0 0 1em 0;
			padding-bottom: 1em;
			border-bottom: rgba(255,255,255,0.2) 1px solid;

			&:last-child{
				border:none;
				padding-bottom: 0;
			}
		}

		a, a:link, a:visited{
			color: white;
			border-bottom: 1px solid currentColor;
		}
		
		a:hover, a:focus{
			color: $blue;
		}
		
		a:active{
			color: white;
		}
		

		ul{
			padding: 0;
			margin: 0;
			list-style:none;
		}

		&:first-child{
			@include media-breakpoint-up(md) {
				margin-top: 2rem;
			}
		}
	}
	&-vertical &-infos{
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-rows:1.5em;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 0px ($grid-gutter-width/2);

			dt{
				grid-row: row 1;
			}

			dd{
				grid-row: row 2;
			}

			dd:last-child{
				border-bottom: rgba(255,255,255,0.2) 1px solid;
				padding-bottom: 1em;
			}
		}
	}

	&-title{
		font-size: 2em;
		// text-shadow: 0 0 1.6em rgba(0,0,0,1);
		text-shadow: 0 0 1em rgba(0,0,0,1);
		margin-top: 1rem;

		@include media-breakpoint-up(md) {
			margin-top: 2rem;
			border-bottom: 0.15rem solid currentColor;
			padding-bottom: 0.75rem;
			margin-bottom: 1.25rem;
		}
		
		@include media-breakpoint-up(xl) {
			// font-size: 3.5em;
			// font-size: 3em;
			font-size: 2.8em;
		}

	}

	&-title-offset-left{
		@include media-breakpoint-up(xl) {
			margin-left: percentage(-5.16666/12);
			// margin-left: percentage(-8/12);
		}
		@include media-breakpoint-up(xxl) {
			margin-left: percentage(-3.7/12);
			padding-right: percentage(3.7/12);
			// margin-left: percentage(-8/12);
		}
	}
	&-vertical &-title-offset-left{
		@include media-breakpoint-up(xl) {
			margin-left: 0;
		}
	}

	&-title-in{
		display: inline-block;
		// padding-bottom: 0.5rem;
		// border-bottom: 0.15rem solid currentColor;
	}

	&-down{
		position: absolute;
		bottom: -5em;

		display: none;

		@include media-breakpoint-up(xl) {
			display: block;
		}
	}

	&-down-link{
		@extend .btn;
	}
	&-vertical &-down{
		position: static;
		bottom: auto;
	}
	&-vertical &-down-link{
		@extend .btn-white;
	}

	&-col-right{
		position: relative;

		margin-top: 1em;
		padding-bottom: 2em;

		// @include media-breakpoint-up(lg) {
			// margin-top: 0;
		// }
	}

	&-form{
		@include make-row;
		margin-bottom: 2em;

		&-first{
			@include media-breakpoint-up(xl) {
				margin-top: 4em;
			}
		}

		&-col{
			@include make-col-ready;
			@include make-col(12);
			@include media-breakpoint-up(md) {
				@include make-col(4);
			}

			&-no-label{
				margin-top: 2em;
			}
		}

		&-button{
			.btn{
				.icn-arrow-h{
					display: none;
				}
				.label-view{
					display: none;
				}

				&.view{
					.icn-magnify{
						display: none;
					}
					.icn-arrow-h{
						display: inline-block;
					}

					.label-search{
						display: none;
					}
					.label-view{
						display: inline-block;
					}
				}
			}
		}

		&-last{
			margin-bottom: -2em;
			justify-content: flex-end;
			text-align: right;
		}
	}

	&-search-col-right{
		@include make-col(12);

		@include media-breakpoint-up(md) {
			@include make-col(6);
		}

		@include media-breakpoint-up(xl) {
			@include make-col(4)
		}
	}

	&-search-col-left{
		@include make-col(12);

		@include media-breakpoint-up(xl) {
			@include make-col(8)
		}
	}
}

.gal{
	margin-bottom: 4em;
	padding: 2em;

	@include media-breakpoint-up(xs) {
		padding: 0;
	}

	&-grid{
		display: flex;
		list-style:none;
		padding: 0;
		margin: 0 -0.5rem;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;

		@include media-breakpoint-up(sm) {
			margin: 0 -($grid-gutter-width/2);
		}
	}

	&-item{
		width: 50%;
		flex-basis: 50%;
		padding: 0.5rem;

		@include media-breakpoint-up(sm) {
			padding: ($grid-gutter-width/2);
		}

		a{
			display: block;
			width: 100%;
		}

		// margin-left: 15px;

		// &:nth-child(odd){
		// 	margin-left: 0;
		// }
		
		// @include media-breakpoint-up(md) {
		// 	margin-left: 45px;

		// 	&:nth-child(odd){
		// 		margin-left: 0;
		// 	}
		// }

		img{
			width: 100%;
			height: auto;
		}
	}
}

// wordpress gallery
.gallery{
	display: flex;
	flex-direction: row;
	margin-left: -1rem;
	margin-right: -1rem;

	&-item{
		border-radius: $radius;
		margin: 1rem;
		padding: 1rem;
		background: $gray-light;
		display: flex;
		flex-direction: column;

		@include at-least(2) {
			width: 50%;
			flex-basis: 50%;
		}

		@include at-least(3) {
			width: 33.33%;
			flex-basis: 33.33%;
		}
	}

	&-icon{
		margin-bottom: 1rem;
	}

	&-icon, &-icon a{
		display: flex;
		flex-grow: 1;

		img{
			width: 100%;
			height: auto;
			justify-self: center;
			align-self: center;
		}
	}

	figcaption{
		margin-top: auto;
		font-size: 0.8em;
		text-align: center;
	}
}

.address{
	margin-bottom: 4em;
	position: relative;
	padding: 0 2rem;
	@include media-breakpoint-up(md) {
		padding: 0;
	}

	// &-link{
	// 	display: block;
	// 	width: 100%;	
	// }
	
	.img-wrap{
		display: block;
	}

	img{
		width: 100%;
		height: auto;
	}

	&-gmaps-link{
		position: absolute;
		bottom: 14%;
		left: 50%;
		transform: translate(-50%, 0);
		width: 80%;
		z-index: 2;
	}
}

.architecture{
	margin-bottom: 4em;
	padding: 2em 2em;

	@include media-breakpoint-up(md) {
		padding: 2em 3em;
	}
}

.infos-error{
	margin-bottom: 4em;
	
	padding: 2rem;
	@include media-breakpoint-up(md) {
		padding: 2em 0;
	}
}

.page-wrapper{
	position: relative;
	z-index: 2;
	margin-top: 256px;
	background: #fff;
}

.page-container{
	padding: 0 2rem;

	@include media-breakpoint-up(sm) {
		padding: 0;
	}
}

.caracteristics{
	&-title-wrapper{
		@include make-col-ready;
		@include make-col(12);
		text-align: right;
	}

	&-title{
		font-size: 3.5em;
	}
}

.caracteristic{
	margin-bottom: 8em;

	&-thumb{
		@include make-col-ready;
		@include make-col(12);
		@include media-breakpoint-up(md) {
			@include make-col(4);
		}
		img{
			max-width: 100%;
			height: auto;
		}
	}

	&-text{
		@include make-col-ready;
		@include make-col(12);
		margin-top: 2em;

		@include media-breakpoint-up(md) {
			@include make-col(7);
			@include make-col-offset(1);
			margin-top: 0;
		}
		align-self: center;
		padding-bottom: 2em;
	}

	&-simple{
		@include make-col-ready;
		@include make-col(12);
		@include media-breakpoint-up(md) {
			@include make-col(5);
			@include make-col-offset(1);
		}
		margin-bottom: 6em;

		&:nth-child(2n+1){
			margin-left: 0;
		}
	}

	&-simple &-text{

	}
}

.results-title{
	margin-bottom: 0;	
	padding: 0 2rem;
	@include media-breakpoint-up(sm) {
		padding: 0;
	}

	&-wrapper{
		@include make-col-ready;
		@include make-col(12);
	}
}

.results-none{
	padding-bottom: 8rem;
}

.row-pagination{
	margin-top: 1em;
	margin-bottom: 2em;
}

.proprietes-list{
	padding: 0 2rem;
	@include media-breakpoint-up(sm) {
		padding: 0;
	}

	&-item{
		@include make-col-ready;
		@include make-col(12);
		@include media-breakpoint-up(sm) {
			@include make-col(6);
		}
		@include media-breakpoint-up(lg) {
			@include make-col(4);
		}
		margin-bottom: $grid-gutter-width;
	}
}

.propriete-card{
	position: relative;
	border-radius: $radius;
	box-shadow: $shadow-light;
	overflow: hidden;
	height: 100%;

	.img-empty{
		height: 0;
		padding-bottom: 109.273183%;
		background: $gray-light;
	}

	&-top{
		margin-bottom: 1.5em;

		img{
			max-width: 100%;
			height: auto;
		}
	}

	&-title{
		display: flex;
		margin: 0 ($grid-gutter-width / 2) 0.75em;
		border-bottom: 0.15rem solid currentColor;
		padding: 0 0 0.5em;
		position: relative;

		span{
			width: 95%;
		}

		.icon{
			margin-left: 1ex;
			width: 5%;
			height: auto;
		}
	}

	&-link{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.pills-list{
	padding: 0 ($grid-gutter-width / 2) 1.5em;
	margin: 0;
	list-style: none;

	&-item{
		display: inline-block;
		max-width: 100%;

		span, a{
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 100%;

			margin-bottom: 0.5em;
			display: inline-block;
			border-radius: 3em;
			background-color: $blue-dark;
			color: #fff;
			padding: 0 0.75em;
		}
	}
}

body .tippy-popper[data-html]{
	max-width: 300px;

	@include media-breakpoint-up(lg) {
		max-width: 480px;
	}
}

.tippy-tooltip.cdb-theme{
	background: #fff; 
	box-shadow: $shadow-light;
	color: $blue-dark;
	padding: 2rem;
	text-align: left;
}
.tippy-tooltip.cdb-theme .tippy-roundarrow {
	fill: #fff;
	box-shadow: $shadow-bottom-light;
}


/**
 * Forms
 */
.form-control{
	border:none;
	font-family: $font-family-base;
	font-weight: 500;
	width: 100%;
	padding: 0.5em 1em;
	background: $gray-lighter;
	transition: box-shadow 250ms $transf, background-color 250ms $transf;
	// box-shadow: 0 1px 0 0 #000; bug in chrome 2017-08-03
	box-shadow: 0px 4px 0px -1px $blue-dark;

	.acf-error &{
		box-shadow: 0px 4px 0px -1px $red;
		background: fade-out($red, 0.5);
	}

	margin-bottom: 2em;

	&:focus{
		// box-shadow: 0 3px 0 0 #000;
		background: $gray-light;
	}
}

.acf-error-message{
	color: $red;
}

.af-input{
	position: relative;

	& > .acf-error-message{
		display: none !important;
		// position: absolute;
		// height: 1.5em;
		// left: 1em;
		// top: 0.5em;
		// width: calc(100% - 2em);
		// margin: 0;
		// pointer-events:none;

		// p{
		// 	margin-bottom: 0;
		// }
	}

	// acf adds a weird wrapper around the element to make it disappear
	& > .acf-temp-remove{

		display: none !important;
	// 	height: 1.5em;
	// 	position: absolute;
	// 	left: 1em;
	// 	top: 0.5em;
	}
}

.af-submit-button{
	@extend .btn;
}

.af-input input, .af-input textarea{
	@extend .form-control;
	outline: none;
}

.af-fields{
	@include make-row;
}

.af-field, .af-submit{
	@include make-col-ready;
	@include make-col(12);
}

.af-submit{
	text-align: right;
}

.af-spinner{
	display: none;
	width: 0.6em;
	height: 0.6em;
	vertical-align: middle;
	background: $blue-dark;
	border-radius: 200%;
	margin-left: 1rem;

	animation: blink 1s infinite;

	&.is-active{
		display: inline-block;
	}
}

/**
 * Chocolat override
 */
.chocolat-wrapper{
	z-index: 10010;
}












