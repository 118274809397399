@font-face {
    font-family:"BBCNassimBold";
    src:url("../fonts/bbc-nassim-bold.woff2") format("woff2"),url("../fonts/bbc-nassim-bold.woff") format("woff"),url("../fonts/bbc-nassim-bold.otf") format("opentype");
    font-style:normal;font-weight:400;
}    

@font-face {
    font-family:"AsapRegular";
    src:url("../fonts/asap-regular.woff2") format("woff2"),url("../fonts/asap-regular.woff") format("woff"),url("../fonts/asap-regular.otf") format("opentype");
    font-style:normal;font-weight:400;
}
