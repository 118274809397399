%btn-with-icon{
	.icon{
		max-width: 1em;
		max-height: 1em;
		width: auto;
		height: auto;
		margin-left: -0.5em;
		margin-bottom: 0.15em;
		fill: currentColor;
		flex-shrink: 0;
	}

	& > span + .icon,
	& > span + .icon + .icon // In case you have two icons that toggle visually
	{
		margin-right: -0.5em;
		margin-left: 0.5em;	
	}

	& > .icon + span,
	& > .icon + span + span // In case you have two icons / labels that toggle visually
	{
		margin-left: 0.5em;
	}

	&.btn-push-icon{
		& > span + .icon{
			margin-left: auto;
		}

		& > .icon + span{
			margin-left: auto;
		}
	}
}

.btn{
	display: inline-block;
	outline: none;
	border: none;
	text-align: center;
	padding: 0.75em 1.75em;
	border-radius: $radius;
	cursor: pointer;
	text-transform: uppercase;

	@extend %btn-with-icon;

	&, &:link, &:visited{
		background: $blue-dark;
		color: #fff;
	}

	&:focus, &:hover{
		color: #fff;
		outline: none;
		box-shadow: none;
		background: #000;
	}
	
	a:active{
		background: $blue-dark;
		color: #fff;
	}

	&-border{
		border: 0.15em solid #fff;
	}
}

.btn-outline{
	position: relative;
	transition: background-color 250ms $transf;

	.outline{
		overflow: visible;
		position: absolute;
		top: -0.3em;
		left: -0.3em;
		width: calc(100% + 0.6em);
		height: calc(100% + 0.6em);
		
		rect{
			transition: none;

			.js.loaded &{
				transition: stroke-dashoffset 750ms $transf;
			}

			.no-js &{
				stroke-dasharray: 665;
				stroke-dashoffset: 665;
				// transition: stroke-dashoffset 750ms $transf;
			}
		}
	}

	&:hover{
		.outline{
			// .no-js &{
				rect{
					stroke-dashoffset: 0;
				}
			// }
		}
	}
}



.btn-outline-pseudo{
	position: relative;
	
	&:before, &:after{
		content: ' ';
		position: absolute;
		width: calc(100% + 0.8em);
		height: calc(100% + 0.8em);
		// border: 0.15em solid #fff;
		border-color: #fff;
		border-style: solid;
		border-radius: $radius;
	}

	&:before{
		top: -0.4em;
		left: -0.4em;
		width: 0;
		height: 0;
		border-width: 0;
		border-bottom: 0;
		border-left: 0;
		transition: width 150ms $transf 450ms, height 150ms $transf 300ms, border-width 0ms ease-out 600ms;
	}
	&:hover:before{
		border-width: 0.15em;
		transition: width 150ms $transf 0ms, height 150ms $transf 150ms, border-width 0ms ease-out 0ms;
		height: calc(100% + 0.8em);
		width: calc(100% + 0.8em);
	}

	&:after{
		bottom: -0.4em;
		right: -0.4em;
		width: 0;
		height: 0;
		border-width: 0;
		border-top: 0;
		border-right: 0;
		transition: width 150ms $transf 150ms, height 150ms $transf 0ms, border-width 0ms ease-out 300ms;
	}
	&:hover:after{
		border-width: 0.15em;
		transition: width 150ms $transf 300ms, height 150ms $transf 450ms, border-width 0ms ease-out 300ms;
		height: calc(100% + 0.8em);
		width: calc(100% + 0.8em);
	}
}


.btn-white{
	color: $blue-dark;
	background: #fff;
	border-radius: $radius;
	box-shadow: $shadow-corners;
	padding: 0.75em 1.75em;
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	transition: background-color 250ms $transf;
	text-transform: uppercase;
	
	@extend %btn-with-icon;

	&, &:link, &:visited{
		
	}
	
	&:hover, &:focus{
		background-color: $blue-dark;
		color: #fff;
	}
	
	&:active{
		
	}

	&-in{
		margin-right: 1em;
	}
}
.blue .btn-white:hover, .blue .btn-white:focus{
	background-color: $gray-light;
	color: $blue-dark;
}

