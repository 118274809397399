body#tinymce {
	margin: 12px !important;

	a:not(.cdb-glossary-word):not(.btn){
		&, &:link, &:visited{
			border-bottom: $border-width solid currentColor;
		}
		
		&:hover, &:focus{
			
		}
		
		&:active{
			
		}
		
	}
	
}
